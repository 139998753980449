<template>
  <div class="home">
    <div :class="[isMobile ? 'goods-list-mobile' : 'goods-list']">
      <div
        class="goods-list-item cursor"
        v-for="(item, key) in goodsList"
        :key="key"
        @mouseover="mouseoverItem(key)"
        @mouseout="mouseoutItem"
        @click.stop="readyToBuy(item)"
        v-ga="{
          eventCategory: 'button',
          eventLabel: 'click goods',
        }"
      >
        <div class="goods-asset">
          <img
            class="goods-image"
            :src="item.image_uri"
            :onerror="'@/assets/image/default.png'"
          />
        </div>
        <div
          :class="[
            'goods-info',
            {
              'goods-info-mobile': isMobile,
            },
          ]"
        >
          <div class="goods-title">
            {{ item.name }}
            <span v-if="item.is_on_sale" class="sale-price-box">
              <span class="sale-price">
                ${{ getTotalPrice(item.actual_price) }}
                <!--                <v-icon color="#ffa70b" size="36" class="icon-sale"-->
                <!--                  >mdi-sale</v-icon-->
                <!--                >-->
              </span>
              <div class="failure-price">${{ getTotalPrice(item.price) }}</div>
            </span>
            <span v-else class="default-price"
              >${{ getTotalPrice(item.actual_price) }}</span
            >
          </div>
          <div>&nbsp;</div>
          <div class="text-unselect desc">{{ item.desc }}</div>
          <div class="footer" v-show="hoverIndex === key && !isMobile">
            <div class="btn-buy">{{ $t("goods.buyNowBtn") }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- dialog  -->
    <v-dialog v-model="readyToBuyDialog" width="500">
      <v-card>
        <v-card-title class="ready-to-buy-dialog-title text-h6 lighten-2">
          {{ $t("goods.invitationCodePrompt") }}
          <v-btn class="btn-close" icon @click="handleCloseReadyToBuyDialog">
            <v-icon color="#000000"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="invite_code"
            :label="invitationCode"
            required
            v-if="!canUpdateGoods"
          ></v-text-field>
          <v-text-field
            v-model="discount_code"
            :label="discountCode"
            required
            v-if="!canUpdateGoods"
          ></v-text-field>
          <v-text-field
            v-model="teacher_code"
            :label="teacherCode"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#539dd7"
            elevation="0"
            width="100%"
            height="3.3em"
            :disabled="canUpdateGoods ? false : invite_code.length === 0"
            @click="btnToBuy"
            class="buy-now-btn"
          >
            {{ $t("goods.buyNowBtn") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isLoading" persistent width="30em">
      <v-card color="#ffffff" dark height="auto">
        <v-card-text>
          <div class="card-title">
            {{ loadingMessage }}
          </div>
          <v-progress-linear
            indeterminate
            height="6px"
            :rounded="true"
            color="#4caf50"
            class="mb-0 progress-bar"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("navDrawer.sureShoppingTitle") }}
        </v-card-title>
        <v-card-text>{{ $t("navDrawer.sureShoppingContent") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-grey darken-1" text @click="btnCancel">
            {{ $t("dialog.disagree") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="btnConfirm">
            {{ $t("dialog.agree") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  get_goods,
  create_order,
  bind_order,
  get_upgrade_goods,
  per_pay,
} from "@/api/goods";
import { mapState } from "vuex";
import { recharge_platform_account } from "@/utils/connet";
import { mint_nft_get } from "@/api/user";
export default {
  name: "GoodsPage",
  data: () => ({
    goodsList: [],
    hoverIndex: -1,
    invite_code: "",
    discount_code: "",
    teacher_code: "",
    buyInfo: {},
    readyToBuyDialog: false,
    isLoading: false,
    confirmDialog: false,
    btnToBuySwitch: false,
    rules: [(value) => !!value || "Required"],
    stateType: 0,
    loadingMessage: "loading",
    iShowNftBtn: false,
  }),
  computed: {
    ...mapState({
      vDefaultElevation: (state) => state.system.vDefaultElevation,
      token: (state) => state.user.token,
      walletAddress: (state) => state.user.walletAddress,
      userId: (state) => state.user.userId,
      canUpdateGoods: (state) => state.user.canUpdateGoods,
      language: (state) => state.system.language,
      hasEnoughDiscountCode: (state) => state.system.hasEnoughDiscountCode,
      nftUrl: (state) => state.user.nftUrl,
    }),
    getTotalPrice() {
      return (price) => {
        return parseFloat(price / 100).toFixed(2);
      };
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    invitationCode() {
      return this.$t("goods.invitationCode");
    },
    discountCode() {
      return this.$t("goods.discountCode");
    },
    teacherCode() {
      return this.$t("goods.teacherCode");
    },
  },
  watch: {
    userId: {
      handler: function (val) {
        if (val) {
          this.getGoodsList();
        }
      },
      immediate: true,
    },
    "$store.state.system.setLanguageIndex": {
      handler: function () {
        this.getGoodsList();
      },
      immediate: true,
    },
  },
  created() {
    this.iShowNftBtn = false;
    this.goodsList = [];
    this.checkIsShowNftBtn();
  },
  methods: {
    goToMintNft() {
      this.$router.push({
        name: "MintNft",
      });
    },
    checkIsShowNftBtn() {
      mint_nft_get().then((res) => {
        console.log(res);
        if (res.message === "ok") {
          this.iShowNftBtn = true;
        }
      });
    },
    btnCancel() {
      this.confirmDialog = false;
    },
    btnConfirm() {
      this.confirmDialog = false;
      this.readyToBuyDialog = true;
    },
    getGoodsList() {
      if (this.token?.length === 0) return;
      if (this.canUpdateGoods) {
        this.getUpgradeGoods();
      } else {
        get_goods({
          lang: this.language === "jp" ? "ja" : this.language,
        }).then((res) => {
          this.goodsList = JSON.parse(JSON.stringify(res.data?.goods ?? []));
        });
      }
    },
    getUpgradeGoods() {
      get_upgrade_goods({
        lang: this.language === "jp" ? "ja" : this.language,
      }).then((res) => {
        this.goodsList = JSON.parse(JSON.stringify(res.data?.goods ?? []));
      });
    },
    mouseoverItem(key) {
      this.hoverIndex = key;
    },
    mouseoutItem() {
      this.hoverIndex = -1;
    },
    readyToBuy(item) {
      this.confirmDialog = true;
      let type = this.canUpdateGoods ? 2 : 1;
      this.buyInfo = Object.assign(
        {},
        {
          goods_id: item.id,
          type: type,
        }
      );
    },
    btnToBuy() {
      if (this.btnToBuySwitch || this.isLoading) {
        this.$store.commit("snackbar/setSnackbar", {
          show: true,
          text: this.$t("message.showText2"),
        });
        return;
      }
      this.btnToBuySwitch = true;
      this.isLoading = true;
      this.readyToBuyDialog = false;
      this.loadingMessage = this.$t("message.waitingTip1");
      if (!this.buyInfo.goods_id) {
        this.btnToBuySwitch = false;
        this.isLoading = false;
        this.$store.commit("snackbar/setSnackbar", {
          show: true,
          text: this.$t("message.showText3"),
        });
        return;
      }
      this.loadingMessage = this.$t("message.waitingTip2");
      if (!this.canUpdateGoods) {
        this.buyInfo.invite_code = this.invite_code;
      }
      this.buyInfo.discount_code = this.discount_code;
      this.buyInfo.teacher_code = this.teacher_code;
      create_order(this.buyInfo)
        .then((res) => {
          console.log("res", res);
          let data = res?.data ?? {};
          if (data?.order_id && data?.token_contract) {
            this.rechargePlatformAccount(data);
          } else {
            this.$store.commit("snackbar/setSnackbar", {
              show: true,
              text: this.$t("message.showText1"),
            });
            this.isLoading = false;
            this.btnToBuySwitch = false;
          }
        })
        .catch((err) => {
          console.error("request create order catch\n", err);
          this.$store.commit("snackbar/setSnackbar", {
            show: true,
            text: err.message || err || this.$t("message.showText4"),
          });
          this.isLoading = false;
          this.btnToBuySwitch = false;
        });
    },
    rechargePlatformAccount(res) {
      this.loadingMessage = this.$t("message.waitingTip3");
      recharge_platform_account(
        {
          decimal: res.token_decimals,
          amount: res.price,
          contractAddress: res.token_contract,
          receivingAddress: res.receiving_address,
          userAddress: this.walletAddress,
        },
        (txHash) => {
          this.stateType = 0;
          this.toBindOrder(res.order_id, txHash);
        },
        (err) => {
          console.log("err", err);
          let text = "";
          if (err?.i18n) {
            text = this.$t("message." + err.i18n);
          } else {
            text = err || this.$t("navDrawer.transactionFail");
          }
          this.$store.commit("snackbar/setSnackbar", {
            show: true,
            text: text,
          });
          this.isLoading = false;
          this.btnToBuySwitch = false;
        },
        (txHash) => {
          per_pay({
            order_id: res.order_id,
            tx_hash: txHash,
          })
            .then((data) => {})
            .catch((err) => {
              console.error("recharge platform account err:\n", err);
              this.$store.commit("snackbar/setSnackbar", {
                show: true,
                text:
                  err.message || err || this.$t("navDrawer.transactionFail"),
              });
              this.isLoading = false;
              this.btnToBuySwitch = false;
            });
        },
        (message) => {
          this.loadingMessage = this.$t("message." + message.i18n);
        }
      );
    },
    toBindOrder(order_id, txHash) {
      this.loadingMessage = this.$t("message.waitingTip4");
      bind_order({
        order_id: order_id,
        tx_hash: txHash,
      })
        .then((data) => {
          if (data.message === "success") {
            this.$store
              .dispatch("user/getUserInfo")
              .then(() => {
                this.isLoading = false;
                this.$store.commit("snackbar/setSnackbar", {
                  show: true,
                  text: data.message,
                });
                this.$router.push({
                  path: "/",
                });
              })
              .catch((err) => {
                this.$store.commit("snackbar/setSnackbar", {
                  show: true,
                  text: err.message || err || this.$t("network.refreshAgain"),
                });
                this.isLoading = false;
              });
          } else {
            this.$store.commit("snackbar/setSnackbar", {
              show: true,
              text: data?.message ?? this.$t("network.refreshAgain"),
            });
            this.isLoading = false;
          }
        })
        .catch((err) => {
          console.log("err", err);
          if (this.stateType <= 10) {
            this.stateType++;
            this.toBindOrder(order_id, txHash);
          } else {
            this.$store.commit("snackbar/setSnackbar", {
              show: true,
              text: err.message || this.$t("network.networkError"),
            });
            this.isLoading = false;
            this.btnToBuySwitch = false;
          }
        });
    },
    handleCloseReadyToBuyDialog() {
      this.readyToBuyDialog = false;
      // this.$refs.readyToBuyform.reset();
      this.buyInfo = Object.assign({});
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding: 2em;
  box-sizing: border-box;
  position: relative;
}

.goods-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}

.goods-list-item {
  width: 200px;
  min-height: 200px;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 15px;
  position: relative;
  margin: 20px auto;
}

.goods-list-item:hover {
  box-shadow: rgb(0 0 0 / 15%) 0px 6px 25px;
  transition: all 0.1s ease 0s;
}

.goods-asset {
  width: 200px;
  height: 200px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}

.goods-image {
  width: 100%;
  height: 100%;
}

.goods-info {
  padding: 12px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90px;
  color: rgb(53, 56, 64);
  position: relative;
  background-color: #ffffff;
}

.goods-info-mobile {
  height: auto;
}

.goods-title {
  font-weight: 600;
  font-size: 14px;
  color: rgb(4, 17, 29);
  position: relative;
}

.show-price-box {
}

.sale-price-box {
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 0;
}

.sale-price {
  font-weight: 600;
  font-size: 16px;
  color: #ffa70b;
  position: relative;
}

.default-price {
  position: absolute;
  top: 0;
  right: 0;
}

.failure-price {
  font-size: 12px;
  text-align: right;
  text-decoration: line-through;
  color: #808080;
  line-height: 14px;
}

.icon-sale {
  position: absolute;
  top: 0;
  left: -40px;
}

.desc {
  font-size: 13px;
}

.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.btn-buy {
  width: 100%;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 28px;
  background-color: #539dd7;
  font-weight: 500;
  font-size: 14px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ready-to-buy-dialog-title {
  position: relative;
  margin-bottom: 2em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.btn-close {
  position: absolute;
  right: 10px;
}

.progress-bar {
  margin-top: 1.4em;
}

.card-title {
  color: #333333;
  padding-top: 0.4em;
  line-height: 1em;
}

.sale-tag {
  width: 80px;
  height: 80px;
  top: -1px;
  right: -1px;
  position: absolute;
  overflow: hidden;
  z-index: 1;
  border-radius: 8px;
}

.sale-tag:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 32px;
  border-right-color: #ffa60c;
  border-top-color: #ffa60c;
}

.icon-sale-tag {
  position: absolute;
  top: 8px;
  right: 8px;
}

@media only screen and (min-width: 768px) {
  .goods-list {
    grid-template-columns: repeat(auto-fill, 286px);
  }

  .goods-list-item {
    width: 286px;
    min-height: 286px;
  }

  .goods-info {
    height: 136px;
  }

  .goods-asset {
    width: 286px;
    height: 286px;
  }

  .goods-info {
    row-gap: 12px;
  }
}

.buy-now-btn {
  color: #ffffff;
}
</style>

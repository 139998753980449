import request from "./request";

export function get_goods(data) {
  return request({
    url: "/api/v1/get_goods",
    method: "get",
    params: data,
  });
}

export function get_upgrade_goods(data) {
  return request({
    url: "/api/v1/get_upgrade_goods",
    method: "get",
    params: data,
  });
}

export function create_order(data) {
  return request({
    url: "/api/v1/create_order",
    method: "put",
    data,
  });
}

export function bind_order(data) {
  return request({
    url: "/api/v1/bind_order",
    method: "post",
    data,
  });
}

export function withdraw(data) {
  return request({
    url: "/api/v1/withdraw",
    method: "put",
    data,
  });
}

export function recharge(data) {
  return request({
    url: "/api/v1/recharge",
    method: "put",
    data,
  });
}

export function per_pay(data) {
  return request({
    url: "/api/v1/per_pay",
    method: "post",
    data,
  });
}

export function storage(data) {
  return request({
    url: "/api/v1/storage",
    method: "put",
    data,
  });
}

export function storage_upgrade(data) {
  return request({
    url: "/api/v1/storage",
    method: "post",
    data,
  });
}
